import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
    const testimonialsList = [
        {
            name: "Achievements",
            quote: "Responsive Design",
            imageUrl: "/assets/img/174.png",
        },
        {
            name: "Achievements",
            quote: "Backend APIs",
            imageUrl: "/assets/img/js.png",
        },        {
            name: "Achievements",
            quote: "Front End Development Libraries",
            imageUrl: "/assets/img/sd.png",
        },
        // Add more testimonials as needed
    ];

    return (
        <section id="testimonials" className="testimonials">
            <div className="container">
                <h2>Testimonials</h2>
                <div className="testimonials-content">
                    {testimonialsList.map((testimonial, index) => (
                        <div className="testimonial" key={index}>
                            <img src={testimonial.imageUrl} alt={testimonial.name} />
                            <h3>{testimonial.name}</h3>
                            <p>{testimonial.quote}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;

import React from 'react';
import './About.css';

const About = () => {
    return (
        <section id="about" className="about">
            <div className="container">
                <h2>About Me</h2>
                <div className="about-content">
                    <div className="about-text">
                        <p>
                        I'm a full stack developer with extensive MERN experience, and other prominent web technologies. I have a passion for creating clean, efficient, and user-friendly web applications.
                        </p>
                        <p>
                            With a background primarily in the trades, I gain from and enjoy computer science just the same. I enjoy solving complex problems and learning new technologies. When I'm not a carpenter or coding, you can find me exploring the outdoors or gardening with my children, working on the short list, or experimenting with some DIY project.
                        </p>
                        <a href="/assets/media/resume.doc" download className="resume-btn">Download Resume</a>
                    </div>
                    <div className="about-photo">
                        <img src="/assets/img/297.jpg" alt="Your portrait" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;

import React from 'react';
import './Blog.css';

const Blog = () => {
    const blogPosts = [
        {
            title: "Exploring OpenAI: Use Cases, Challenges, and an Optimistic Outlook",
            excerpt: " OpenAI's models have a wide range of applications, including natural language processing, image generation, and even code generation.",
            imageUrl: "/assets/img/openai-1.webp",
            postUrl: "/assets/media/openai-blog.html"
        },
        {
            title: "Self Learning and Self Learning Software/Web Development",
            excerpt: " Self-learning, also known as self-directed learning, is a process in which an individual takes control of their own learning journey.",
            imageUrl: "/assets/img/blog2.png",
            postUrl: "/assets/media/selftaught-blog.html"
        },
        // Add more blog posts as needed
    ];

    return (
        <section id="blog" className="blog">
            <div className="container">
                <h2>Blog</h2>
                <div className="blog-content">
                    {blogPosts.map((post, index) => (
                        <div className="blog-post" key={index}>
                            <a href={post.postUrl} target="_blank" rel="noopener noreferrer">
                                <img src={post.imageUrl} alt={post.title} />
                                <div className="post-info">
                                    <h3>{post.title}</h3>
                                    <p>{post.excerpt}</p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Blog;

import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import HelpDeskAvatar from './components/HelpDeskAvatar';

function App() {
    return (
        <div>
            <Header />
            <Hero />
            <About />
            <Skills />
            <Projects />
            <Blog />
            <Contact />
            <Testimonials />
            <Footer />
            <HelpDeskAvatar/>
            {/* Other components go here */}
        </div>
    );
}

export default App;

import React from 'react';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="container">
                <p>&copy; {currentYear} Chris Munds. All rights reserved.</p>
                <div className="social-links">
                    <a href="https://github.com/mvmcl" target="_blank" rel="noopener noreferrer">GitHub</a>
                    <a href="https://linkedin.com/in/mvmclx" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    <a href="https://twitter.com/penstocktem" target="_blank" rel="noopener noreferrer">Twitter</a>
                    {/* Add more social links as needed */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import React from 'react';
import './Skills.css';

const Skills = () => {
    return (
        <section id="skills" className="skills">
            <div className="container">
                <h2>Skills</h2>
                <div className="skills-content">
                    <div className="skill">
                        <h3>JavaScript</h3>
                    </div>
                    <div className="skill">
                        <h3>React</h3>
                    </div>
                    <div className="skill">
                        <h3>Node.js</h3>
                    </div>
                    <div className="skill">
                        <h3>HTML/CSS</h3>
                    </div>
                    <div className="skill">
                        <h3>Git</h3>
                    </div>
                    <div className="skill">
                        <h3>MySQL</h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Skills;

// HelpDeskAvatar.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import avatar from './298.jpg'
import {FallingLines, ThreeDots} from 'react-loader-spinner'
import EmojiPicker from 'emoji-picker-react'
const KEY = process.env.REACT_APP_OPENAI_API_KEY
const Avatar = styled.img`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  border-radius:100%;
`;
const MessageStatus = styled.span`
  font-size: 0.8em;
  color: #888;
`;
const InputMessage = styled.input`
  flex-grow: 1;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 10px;
`;

const ChatWidget = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  background-color: #2f8cff;
  color: white;
  padding: 12px 20px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;const EmojiPickerWrapper = styled.div`
position: absolute;
bottom: 60px;
right: 20px;
max-width:100px;
max-height: 200px;
overflow-y: auto;
background-color: white;
border: 1px solid #ccc;
border-radius: 5px;
padding: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  background-color: #2f8cff;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 12px;
  cursor: pointer;
`;
const FileInput = styled.input`
  display: none;
`;
const Chat = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;const UserInput = styled.form`
display: flex;
padding: 10px 20px;
border-top: 1px solid #ccc;
`;

const Message = styled.div`
  margin-bottom: 10px;
`;




















const HelpDeskAvatar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [isMinimized, setIsMinimized] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [userMessage, setUserMessage] = useState('');

  const handleKeyUp = useCallback((e) => {
    if (e.key === 'Escape') {
      if (showEmojiPicker) {
        setShowEmojiPicker(false);
      } else {
      setIsOpen(false);
    }}
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    scrollToBottom()
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [messages, handleKeyUp]);
  const handleClickOutside = (event) => {
    if (chatWidgetRef.current && !chatWidgetRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  
  const onEmojiClick = (event, emojiObject) => {
    setUserMessage(userMessage + emojiObject.emoji);
  };
  
  const handleUserMessageSubmit = async (e) => {
    e.preventDefault();
    const newMessage = {
      sender: name || 'User',
      message: userMessage,
      timestamp: new Date().toLocaleTimeString(),
      status: 'Sending...',
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  
    try {
      setIsLoading(true);
      const aiMessage = await sendMessage(userMessage);
      setIsLoading(false);
  
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((msg) =>
          msg === newMessage ? { ...msg, status: 'Sent' } : msg
        );
        return [
          ...updatedMessages,
          {
            sender: 'Assistant',
            message: aiMessage,
            timestamp: new Date().toLocaleTimeString(),
            status: 'Received',
          },
        ];
      });
    } catch (error) {
      console.error('Error communicating with OpenAI API:', error);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg === newMessage ? { ...msg, status: 'Failed' } : msg
        )
      );
    }
  
    setUserMessage('');
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: name || 'User', message: reader.result, type: 'image' },
        ]);
      };
    }
  };
  


  
  const handleAvatarClick = () => {
    setIsOpen(!isOpen);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsFormVisible(false);
  };
  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };
  

const chatRef = useRef()  
const chatWidgetRef = useRef()


  const sendMessage = async (message) => {
    const response = await axios.post('https://api.openai.com/v1/chat/completions', {
      model: "gpt-4-0314",
        messages:[{"role":"user", "content":`${userMessage}`}],
        max_tokens: 3024,
        temperature: 0.77,     
    }, {
      headers: {
        'Authorization': `Bearer ${KEY}`,
        'Content-Type': 'application/json',
      },
    });
    const timestamp = new Date().toLocaleString();
    const aiMessage = response.data.choices[0].message.content;
    setMessages((prevMessages) => [...prevMessages, { sender:'Assistant', message: aiMessage }]);
  };
  const handleMinimizeClick = () => {
    setIsMinimized(!isMinimized);
  };
  return (
       <>
         <Avatar src={avatar} className="avatar"onClick={handleAvatarClick} />
         {isOpen && (
           <ChatWidget ref={chatWidgetRef}>
             <Header>Help Desk  <Button onClick={handleMinimizeClick} style={{ float: 'right', fontSize: '1.2em' }}>
    {isMinimized ? '▲' : '▼'}
  </Button></Header>
             {isFormVisible ? (
               <Form onSubmit={handleFormSubmit}>
                 <Label htmlFor="name">Name:</Label>
                 <Input
                   id="name"
                   type="text"
                   value={name}
                   onChange={(e) => setName(e.target.value)}
                   required
                 />
                 <Label htmlFor="email">Email:</Label>
                 <Input
                   id="email"
                   type="email"
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
                   required
                 />
                 <Button type="submit">Start Chat</Button>
               </Form>
             ) : (
              <>
              <Chat ref={chatRef}>
                {messages.map((message, index) => (
             <Message key={index}>
             <strong>
               {message.sender} ({message.timestamp}):
             </strong>{' '}
             {message.type === 'image' ? (
               <img
                 src={message.message}
                 alt="user-upload"
                 style={{ maxWidth: '200px', maxHeight: '200px' }}
               />
             ) : (
               message.message
             )}
           </Message>
                  
                ))}{isLoading && (<FallingLines type="Triangle" color="#2f8cff" height={40} width={40} timeout={30000}/>)}
              </Chat>
              <UserInput onSubmit={handleUserMessageSubmit}>
              <FileInput
    type="file"
    id="imageUpload"
    accept="image/*"
    onChange={handleImageUpload}
  />
<label htmlFor="image-upload">
<Button
    type="button"
    onClick={() => document.getElementById('imageUpload').click()}
  >
    Upload Image
  </Button>
</label>

                <InputMessage
                  type="text"
                  placeholder="Type your message..."
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                />
              
                <Button type="submit">Send</Button>
              </UserInput>   <Button onClick={() => setShowEmojiPicker(!showEmojiPicker)}>😀</Button>
  {showEmojiPicker && (<EmojiPickerWrapper><EmojiPicker onEmojiClick={onEmojiClick} /></EmojiPickerWrapper>)}
            </>
             )}
           </ChatWidget>
         )}
       </>
     )}

     export default HelpDeskAvatar
     
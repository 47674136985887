import React from 'react';
import './Projects.css';

const Projects = () => {
    const projectList = [
        {
            title: "Chore Chart generator",
            description: "A small openai project to create a chore chart for the week.",
            imageUrl: "/assets/img/bds.png",
            projectUrl: "best-dollar-spent.onrender.com"
        },
        {
            title: "Website implementation",
            description: "Some openai, some MERN, little of this and a little of that.",
            imageUrl: "/assets/img/pm.png",
            projectUrl: "portfolio-mvmclx.vercel.app"
        },        {
            title: "IP GEO",
            description: "IP address geo location tool",
            imageUrl: "/assets/img/ip.png",
            projectUrl: "https://mvmcl.github.io/pages.trackip/"
        },
        // Add more projects as needed
    ];

    return (
        <section id="projects" className="projects">
            <div className="container">
                <h2>Projects</h2>
                <div className="projects-content">
                    {projectList.map((project, index) => (
                        <div className="project" key={index}>
                            <a href={project.projectUrl} target="_blank" rel="noopener noreferrer">
                                <img src={project.imageUrl} alt={project.title} />
                                <div className="project-info">
                                    <h3>{project.title}</h3>
                                    <p>{project.description}</p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;
